import React from 'react'
import PreHeader from '../components/PreHeader'
import Header from '../components/Header'
import Helmet from 'react-helmet'
import Footer from '../components/Footer'
import {Link} from 'gatsby'

const NotFoundPage = () => (
  <div id="app">
    <Helmet>
      <meta charSet="utf-8" />
      <meta name="Description" content="WRAL Digital Solutions Refresh"></meta>
      <title>WRAL Digital Solutions | Contact Us</title>
      <link rel="canonical" href="https://www.wraldigitalsolutions.com/404" />
    </Helmet>

      <PreHeader/>
      <Header/>

      <div id="fourohfour">
        <h1>NOT FOUND</h1>
        <p>The page you're trying to reach doesn't exist</p>
        <br/>
        <Link to="/">Click here to go home</Link>
      </div>
  <Footer/>
  </div>

)

export default NotFoundPage
